import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class AuthService {

  constructor(private http: HttpClient) { }

  
  public consumirApiPOSTAuth(url: string, object: any) {
    const headerDict = {
      "Content-Type": "application/json",
      // authorization: this.currentTokenValue,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    // object.usuario_creacion = this.currentUser.usuario;
    return this.http.post(url, object, requestOptions);
  }

  public consumirApiGETAuth(url: string, params?: any) {
    const headerDict = {
      "Content-Type": "application/json",
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
      params,
    };
    return this.http.get(url, requestOptions);
  }

}
