import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PaginaComponent } from "./pagina.component";
import { BodyComponent } from "./body/body.component";
import { TerminosComponent } from "./terminos/terminos.component";

const routes: Routes = [
    {
        path: '',
        component: PaginaComponent,
        children: [
            { path: '', component: BodyComponent },
            { path: 'terminos_y_condiciones', component: TerminosComponent }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaginaRoutingModule { }

