import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginaComponent } from './pagina.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuroraComponent } from './aurora/aurora.component';
import { PaginaRoutingModule } from './pagina-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './base/base.component';
import { BodyComponent } from './body/body.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TerminosComponent } from './terminos/terminos.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    PaginaComponent,
    HeaderComponent,
    FooterComponent,
    BaseComponent,
    BodyComponent,
    AuroraComponent,
    TerminosComponent
  ],
  imports: [
    CommonModule,
    PaginaRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoModule,
    PdfViewerModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaginaModule { }
