import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/base/base.component';

const routes: Routes = [

  // { path: '', redirectTo: 'es', pathMatch: 'full' },

  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pagina.module').then(m => m.PaginaModule)
      }
    ]
  },
  
  // { path: 'error', component: ErrorPageComponent, data: { 'type': 404 } },
  

  // { path: '**', redirectTo: 'error', pathMatch: 'full' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
