import { Component } from '@angular/core';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent {

  pdfSrc: string =  `https://application.infinitumscitech.co:900/uploads/Politica_de_Tratamiento_de_Datos_Personales_6dbb36a40f.pdf`;

}
