import { DOCUMENT } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContenidoService } from 'src/app/core/services/contenido.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  ContentData: any = [];

  nombre: string = '';
  email: string = '';
  telefono: string = '';
  check: boolean = false;
  Form: FormGroup;

  constructor(private srv: ContenidoService, private FB: FormBuilder) {
    this.srv.loading = true;
    this.Form = this.FB.group({
      nombre: ['', Validators.required],
      telefono: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mensaje: ['']
    });
  }

  get loading() {
    return this.srv.loading;
  }

  navigateToSection(path: string) {
    // Utilizamos pushState para cambiar la URL a la ruta de la sección
    history.pushState({}, '', path);

    // Desplazamos suavemente a la sección utilizando scrollIntoView
    const sectionId = path.substring(1); // Eliminamos el primer "/" de la ruta
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  }

  // Capturamos el evento "popstate" para desplazarnos a la sección correcta al utilizar los botones de navegación del navegador
  @HostListener('window:popstate', ['$event'])
  popstate($event: any) {
    const sectionId = window.location.pathname.substring(1); // Eliminamos el primer "/" de la ruta
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: 'smooth' });
  }


  async ngOnInit() {
    try {
      const response: any = await this.srv.obtenerdatos().toPromise();
      this.srv.loading = false;
      if (response.status) {
        this.ContentData = response.data;
      }
    } catch (error) {
      this.srv.loading = false;
      console.log(error);
    }
  }

  get Header() {
    return this.ContentData.header == null ? null : this.ContentData.header[0];
  }

  get Detalles() {
    return this.ContentData.detalles;
  }

  get Caracteristicas() {
    return this.ContentData.caracteristicas;
  }

  get Testimonios() {
    return this.ContentData.testimonios;
  }

  esPar(numero: number) {
    return (numero % 2) == 0;
  }

  get isValid() {
    return this.nombre != '' && this.email != '' && this.telefono != '' && this.check == true ? true : false;
  }


  get FormValid(): boolean {
    if (this.check == false) {
      return false;
    }
    return this.Form.invalid ? false : true;
  }

  async submit() {

    try {
      const data = {
        nombre: this.Form.controls['nombre'].value,
        empresa: this.Form.controls['empresa'].value,
        email: this.Form.controls['email'].value,
        telefono: this.Form.controls['telefono'].value,
        mensaje: this.Form.controls['mensaje'].value
      };

      const response: any = await this.srv.formulario(data).toPromise();
      if (response.status) {

      }
    } catch (error) {
      console.log(error);
    }

  }

  terminos() {
    this.check = !this.check;
  }


}
