<ng-container *transloco="let t">

    <div id="loading" *ngIf="loading">
        <img src="assets/img/infinitumscitech.svg" alt="{{ t('cargando') }}">
    </div>


    <!-- BANNER -->
    <div id="inicio" *ngIf="Header != null" class="page-header header-filter">
        <div class="squares square1"></div>
        <div class="squares square2"></div>
        <div class="squares square3"></div>
        <div class="squares square4"></div>
        <div class="squares square5"></div>
        <div class="squares square6"></div>
        <div class="squares square7"></div>
        <div class="container d-flex align-items-center">
            <div class="section">
                <div class="container">
                    <div class="title">
                        <img src="assets/img/infinitumscitech.svg" class="logo_page" alt="infinitum sci-tech">
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <div data-aos="fade-right" class="col-lg-5 mb-5 mb-lg-0 ">
                            <h1 class="text-white font-weight-light">{{ Header.titulo }}</h1>
                            <p class="text-white mt-4">{{ Header.descripcion }}</p>
                            <!-- <span (click)="navigateToSection('/demostracion')" class="btn btn-danger mt-4">{{
                                Header.boton }}</span> -->
                            <span (click)="navigateToSection('/contactanos')" class="btn btn-danger mt-4">Agenda un
                                demo</span>
                        </div>
                        <div data-aos="fade-left" class="col-lg-6">
                            <figure class="figure">
                                <video class="figure-img img-fluid rounded" loop autoplay poster controls>
                                    <!-- <source src="https://storagerecordcall.blob.core.windows.net/paginaweb/header_es.mp4" type="video/mp4"> -->
                                    <source src="{{ Header.media }}" type="video/mp4">
                                </video>
                                <figcaption class="figure-caption text">
                                    <span title="Obra: Diferente">Música de <a href="https://www.fiftysounds.com/es/"
                                            aria-label="fiftysounds" target="_blank">fiftysounds</a></span>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN BANNER -->



    <!-- CONTEO -->
    <div class="container mb-5 pb-5">
        <div class="section">

            <div class="container align-items-center">
                <div class="row">

                    <div data-aos="fade-left" class="col-lg-4 col-12">
                        <p class="text-on-back-2">+<span class="counter" data-target="100">0</span>M</p>
                        <h1 class="profile-title text-center">{{ t('numeros.text1') }}</h1>
                    </div>
                    <div data-aos="fade-up" class="col-lg-4 col-12">
                        <p class="text-on-back-2">+<span class="counter" data-target="25">0</span>%</p>
                        <h1 class="profile-title text-center">{{ t('numeros.text2') }}</h1>
                    </div>
                    <div data-aos="fade-right" class="col-lg-4 col-12">
                        <p class="text-on-back-2">+<span class="counter" data-target="90">0</span>%</p>
                        <h1 class="profile-title text-center">{{ t('numeros.text3') }}</h1>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- FIN CONTEO -->



    <!-- DETALLES -->
    <div class="container" id="detalles">
        <div class="container text-center">

            <ng-container *ngFor="let container of Detalles">
                <div class="section">
                    <div class="row justify-content-between align-items-center">
                        <div *ngFor="let row of container" data-aos="fade-up" class="col-lg-4 mb-4 mb-lg-0 ">
                            <div class="p-3">
                                <img src="{{ row.media }}" alt="{{ row.titulo }}">
                                <h1 class="text-white font-weight-light">{{ row.titulo }}</h1>
                                <p class="text-white mt-4">{{ row.descripcion }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
    <!-- FIN DETALLES -->



    <!-- AGENDA UN DEMO -->
    <div class="section"></div>
    <div class="section border-top border-bottom border-danger">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div data-aos="fade-right" class="col-12 mb-lg-0 text-center">
                    <h2 class="text-white font-weight-light">Empieza de inmediato y prueba nuestra plataforma totalmente
                        gratis</h2>
                    <span aria-label="Agenda un demo" (click)="navigateToSection('/contactanos')"
                        class="btn btn-danger mt-4">Agenda un demo</span>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN AGENDA UN DEMO -->



    <!-- AURORA -->
    <!-- <div id="demostracion">
        <app-aurora></app-aurora>
    </div> -->
    <!-- FIN AURORA -->



    <!-- CARACTERISTICAS -->
    <div class="section">
        <div class="section">
            <div class="text-center">
                <h1>{{ t('caracteristicas') }}</h1>
            </div>
        </div>
        <div class="section" *ngFor="let row of Caracteristicas; let i = index">

            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div data-aos="fade-up" class="col-lg-6" *ngIf="!esPar(i)">
                        <figure class="figure">
                            <video *ngIf="row.media != null" src="{{ row.media }}" preload
                                class="figure-img img-fluid rounded" poster controls></video>
                        </figure>
                    </div>
                    <div data-aos="zoom-in-up" class="col-lg-6 mb-6 mb-lg-0 ">
                        <h1 class="text-white font-weight-light">{{ row.titulo }}</h1>
                        <p class="text-white mt-4">{{ row.descripcion }}</p>
                        <!-- <span (click)="navigateToSection('/demostracion')" class="btn btn-danger mt-4">{{ row.boton
                            }}</span> -->
                        <span (click)="navigateToSection('/contactanos')" class="btn btn-danger mt-4">Agenda un
                            demo</span>
                    </div>
                    <div data-aos="fade-up" class="col-lg-6" *ngIf="esPar(i)">
                        <figure class="figure">
                            <video *ngIf="row.media != null" src="{{ row.media }}" preload
                                class="figure-img img-fluid rounded" poster controls></video>
                            <figcaption class="figure-caption text">
                                <span title="Obra: El Mejor Momento">
                                    Música de <a href="https://www.fiftysounds.com/es/" aria-label="fiftysounds"
                                        target="_blank">fiftysounds</a>
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- FIN CARACTERISTICAS -->



    <!-- META -->
    <!-- <div class="section"></div> -->
    <div class="section border-top border-bottom border-danger">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div data-aos="fade-right" class="col-lg-6 mb-6 mb-lg-0 ">
                    <h1 class="text-white font-weight-light">INFINITUM SCI TECH es un Meta Business Partner</h1>
                    <p class="mt-4">Lo que significa que
                        contamos con un excelente equipo de profesionales que te ayudarán a crear experiencias
                        conversacionales a través de WhatsApp, Facebook e Instagram para hacer crecer tu negocio.</p>
                </div>
                <div data-aos="fade-left" class="col-lg-6">
                    <figure class="figure">
                        <img src="assets/img/Meta.png" alt="Meta Business">
                    </figure>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN META -->



    <!-- TESTIMONIO -->
    <div class="section" id="confian_en_nosotros">
        <div class="section">
            <div class=" text-center">
                <h1>{{ t('testimonio') }}</h1>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div id="carouselExampleControls" class="carousel slide">
                        <div class="carousel-inner">
                            <div *ngFor="let testi of Testimonios; let i = index" class="carousel-item"
                                id="carrousel_{{ i }}">
                                <div class="container">
                                    <div class="row text-center">

                                        <div *ngFor="let row of testi" class="col-md-3">
                                            <!-- Nav tabs -->
                                            <div class="card card-image">
                                                <div class="card-header">
                                                    <img src="{{ row.media }}" alt="{{ row.nombre }}">
                                                </div>
                                                <div class="card-body">
                                                    <!-- Tab panes -->
                                                    <div class="tab-pane active" id="link1">
                                                        <small class="text-uppercase text-white">{{ row.nombre
                                                            }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-md-3" *ngIf="testi.length < 4">
                                            <!-- Nav tabs -->
                                            <div class="card card-image">
                                                <div class="card-header">
                                                    <i class="bi bi-plus text-on-back-3 text-white"></i>
                                                </div>
                                                <div class="card-body">
                                                    <!-- Tab panes -->
                                                    <div class="tab-pane active" id="link1">
                                                        <small class="text-uppercase text-white">Muchos más</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FIN TESTIMONIO -->



    <!-- FORMULARIO -->
    <section class="section section-lg section-safe">
        <div id="contactanos" class="section-signup">
            <div class="container">
                <div class="squares square-1"></div>
                <div class="squares square-2"></div>
                <div class="squares square-3"></div>
                <div class="squares square-4"></div>

                <div class="row row-grid justify-content-between">
                    <div class="col-md-5">
                        <img src="assets/img/contactanos.jpg" alt="contactanos" class="img-fluid floating">
                        <div class="card card-stats bg-info">
                            <div class="card-body">
                                <div class="justify-content-center">
                                    <div class="numbers">
                                        <p class="card-category text-white">Automatiza procesos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-stats bg-danger">
                            <div class="card-body">
                                <div class="justify-content-center">
                                    <div class="numbers">
                                        <p class="card-category text-white">+ productividad</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-stats bg-default">
                            <div class="card-body">
                                <div class="justify-content-center">
                                    <div class="numbers">
                                        <!-- <p class="card-title">10 425</p> -->
                                        <p class="card-category text-white">Fideliza tus clientes</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="section">
                            <div class="container">

                                <div class="row">
                                    <div class="col-12">

                                        <h3 class="display-3 text-white">{{ t('form.detalle') }}</h3>
                                        <p>{{ t('form.mensaje') }}</p>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                    <div class="col-lg-6">
                        <form class="card card-register" [formGroup]="Form" (submit)="submit()">
                            <div class="card-header">
                                <h1 class="card-title text-white">{{ t('form.titulo') }}</h1>
                            </div>
                            <div class="card-body">
                                <div class="form">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="bi bi-person-circle"></i>
                                            </div>
                                        </div>
                                        <input type="text" formControlName="nombre" autocomplete="off"
                                            class="form-control" placeholder="{{ t('form.nombre') }}">
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="bi bi-buildings"></i>
                                            </div>
                                        </div>
                                        <input type="text" formControlName="empresa" autocomplete="off"
                                            placeholder="{{ t('form.empresa') }}" class="form-control">
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="bi bi-envelope-at"></i>
                                            </div>
                                        </div>
                                        <input type="email" formControlName="email" autocomplete="off"
                                            placeholder="{{ t('form.email') }}" class="form-control">
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="bi bi-phone"></i>
                                            </div>
                                        </div>
                                        <input type="number" formControlName="telefono" autocomplete="off"
                                            class="form-control" placeholder="{{ t('form.telefono') }}">
                                    </div>

                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="bi bi-text-left"></i>
                                            </div>
                                        </div>
                                        <textarea class="form-control" autocomplete="off" formControlName="mensaje"
                                            placeholder="{{ t('form.texto') }}"></textarea>
                                    </div>
                                    <div class="form-check text-left">
                                        <label class="form-check-label">
                                            <input (change)="terminos()" aria-label="terminos y condiciones"
                                                class="form-check-input" type="checkbox">
                                            <span class="form-check-sign"></span>
                                            <a class="text-white" aria-label="Terminos y condiciones"
                                                [routerLink]="['/terminos_y_condiciones']" fragment="ayudaDiv"
                                                target="_blank">
                                                {{ t('form.terminos') }}
                                            </a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button type="submit" [disabled]="!FormValid" class="btn btn-info btn-round btn-lg">{{
                                    t('form.button') }}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- FIN FORMULARIO -->



</ng-container>
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>