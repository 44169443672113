import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContenidoService } from 'src/app/core/services/contenido.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  loading: Boolean = true;

  constructor(private srv: ContenidoService, private route: ActivatedRoute,
    private translocoService: TranslocoService) {

    this.srv.obtenerdatos(true).subscribe((res: any) => {
      this.loading = false;
      if (res.status) {
        this.srv.menus = res.data;
      }
    }, error => {
      this.loading = false;
      console.log(error);
    });
  }

  ngOnInit(): void {
    this.translocoService.setActiveLang(this.srv.lang);
  }

}
